import React, { useState } from "react";
import "./home.scss";
import arrow from "../../assets/Union.png";
import arrowm from "../../assets/Unionm.png";
import left from "../../assets/leftBranch.png";
import right from "../../assets/rightBranch.png";
import center from "../../assets/centerBranch.png";
import carlogo from "../../assets/carlogo.png";
import bannerIcon from "../../assets/bannerIcon.png";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FiInstagram } from "react-icons/fi";
import { FaDiscord, FaFacebook, FaTelegram } from "react-icons/fa";
function Home() {
  const [select, setselect] = useState(0);
  const onMouseEnter = (e: number) => {
    setselect(e);
  };
  const onMouseLeave = (e: number) => {
    setselect(e);
  };
  return (
    <div className="home">
      <div className="top_container">
        <div className="heading">
          <h1>
            Welcome to <br /><span>&nbsp;Carbify’s Consumer Hub</span>!
          </h1>
        </div>
        <p>
          Discover how Carbify is revolutionizing the fight against climate
          change with innovative blockchain solutions and sustainable practices.
          Whether you're an individual looking to contribute or a business
          seeking impactful solutions, we provide the tools to make a
          difference. Explore our platforms, resources, and initiatives to take
          the first step toward a greener, more sustainable future today.
          <br />
          <br />
          Join the conversation on
          <a href=" https://discord.gg/carbify"> Discord</a> and be part of the
          Carbify community!
        </p>
      </div>
      <div className="bottom">
        <div className="tree">
          <div
            className="main_tree"
            onMouseEnter={() => onMouseEnter(1)}
            onMouseLeave={() => onMouseLeave(0)}
          >
            <div className="branch move">
              <button
                className="link_nav"
                onClick={() =>
                  window.open("https://docs.carbify.io/", "_blank")
                }
              >
                Greenpaper
              </button>
              <img src={center} alt="" />
            </div>
          </div>
          <div
            className="left_tree"
            onMouseEnter={() => onMouseEnter(2)}
            onMouseLeave={() => onMouseLeave(0)}
          >
            <div className="branch move">
              <button
                className="link_nav"
                onClick={() => window.open("https://carbify.io/", "_blank")}
              >
                Corporate Solutions
              </button>
              <img src={left} alt="" />
            </div>
          </div>
          <div
            className="right_tree"
            onMouseEnter={() => onMouseEnter(3)}
            onMouseLeave={() => onMouseLeave(0)}
          >
            <div className="branch move">
              <button
                className="link_nav"
                onClick={() =>
                  window.open("https://dashboard.carbify.io/", "_blank")
                }
              >
                Carbidash
              </button>
              <img src={right} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="home_back">
        <img src={arrow} alt="" />
        <img src={arrowm} alt="" />
      </div>

      <div className="bottom_back">
        <div className="container">
         
          <div className="terms">
            <a
              href="https://carbify.io/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy Policy
            </a>
            <a
              href="https://carbify.io/terms-and-conditions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms & Conditions
            </a>
            <p> © 2023-2024 Carbify. All rights reserved.</p>
          </div>
          <img src={carlogo} alt="" />
          <div className="link_path">
            <button
              onClick={() => window.open("https://x.com/Carbify_io/", "_blank")}
            >
              <FaSquareXTwitter />
            </button>

            <button
              onClick={() =>
                window.open("https://www.instagram.com/carbify.io/", "_blank")
              }
            >
              <FiInstagram />
            </button>
            <button
              onClick={() =>
                window.open("https://discord.gg/carbify", "_blank")
              }
            >
              <FaDiscord />
            </button>
            <button
              onClick={() =>
                window.open(
                  "https://www.facebook.com/CarbifyOfficial/",
                  "_blank"
                )
              }
            >
              <FaFacebook />
            </button>
            <button
              onClick={() => window.open("https://t.me/carbify", "_blank")}
            >
              <FaTelegram />
            </button>
          </div>
        </div>
      </div>

      {select === 2 ? (
        <div className="side_box">
          <header>
            <img src={bannerIcon} alt="" />
            <h2>Corporate Solutions</h2>
          </header>
          <p>
            Carbify's core business revolves around providing carbon offsets and
            planting agroforestry systems in Brazil and Angola. The business
            pages focus on these products and everything related to them,
            including compliance, technical development, sales, marketing, and
            more, and are intended exclusively for businesses and corporate
            clients rather than B2C customers.
          </p>
        </div>
      ) : select === 3 ? (
        <div className="side_box">
          <header>
            <img src={bannerIcon} alt="" />
            <h2>Carbidash</h2>
          </header>
          <p>
            Carbidash is our all-in-one platform where you can purchase trees,
            stake them, claim CO2, generate CO2 certificates, sell CO2, and much
            more—managing all your ecosystem features seamlessly in one place.
            This platform is specifically designed for individual customers and
            B2C clients.
          </p>
        </div>
      ) : select === 1 ? (
        <div className="side_box">
          <header>
            <img src={bannerIcon} alt="" />
            <h2>Greenpaper</h2>
          </header>
          <p>
            In the greenpaper, you'll find a comprehensive explanation of the
            Carbify project, the problems we address, and the solutions we
            provide. It also includes detailed information about tokenomics,
            company wallets, and the use cases we offer.
          </p>
        </div>
      ) : null}
    </div>
  );
}

export default Home;
